import http from '~/utils/http'


export class OpenVipApi {
  // @POST('/jav/openvip/coinBalance')
  // Future<Account> coinBalance();
  static coinBalance(opt?: HttpOptions) {
    return http.post<Account>({
      ...opt,
      url: '/jav/openvip/coinBalance'
    })
  }

  // @POST('/jav/openvip/coinBuy')
  // Future<void> coinBuy(@Body() CoinBuyParam param);
  static coinBuy(params: CoinBuyParam, opt?: HttpOptions) {
    return http.post<any>({
      ...opt,
      url: '/jav/openvip/coinBuy',
      body: params
    })
  }

  // @POST('/jav/openvip/coinList')
  // Future<List<Sku>> coinList();
  static coinList(opt?: HttpOptions) {
    return http.post<Array<Sku>>({
      ...opt,
      url: '/jav/openvip/coinList'
    })
  }

  // @POST('/jav/openvip/ticketsList')
  // Future<List<Sku>> ticketsList();
  static ticketsList(opt?: HttpOptions) {
    return http.post<Array<Sku>>({
      ...opt,
      url: '/jav/openvip/ticketsList'
    })
  }

  // @POST('/jav/openvip/vipCardList')
  // Future<List<Vip>> vipCardList();
  static vipCardList(opt?: HttpOptions) {
    return http.post<Array<Vip>>({
      ...opt,
      url: '/jav/openvip/vipCardList'
    })
  }
}
